<template>
  <a-breadcrumb class="bread-wrapper" separator="/">
    <a-breadcrumb-item v-for="(item, index) in breadList" :key="item.path">
      <router-link v-if="index === 0 && breadList.length > 1" :to="item.path">{{
        item.name
      }}</router-link>
      <span v-else>{{ item.name }}</span>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  name: 'breadCrumb',
  watch: {
    $route() {
      this.getBreadCrumb();
    }
  },
  data() {
    return {
      breadList: []
    };
  },
  created() {
    this.getBreadCrumb();
  },
  methods: {
    getBreadCrumb() {
      let matched = this.$route.matched.filter((item) => {
        return item.name;
      });
      let home = {
        path: '/home',
        name: '首页'
      };
      if (matched[0].path === '/home') {
        this.breadList = [home];
      } else {
        this.breadList = [home, ...matched];
      }
    }
  }
};
</script>
<style lang="less" scoped>
.bread-wrapper {
  line-height: 54px;
  margin-left: 13px;
}
</style>
